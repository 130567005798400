<template>
<v-app id="inspire">
    <v-content>
        <v-container fluid fill-height>
            <v-layout align-center justify-center>
                <v-flex xs12 sm8 md4>
                    <ValidationObserver ref="obs" v-slot="{ invalid, validated, passes, validate }">
                        <v-card class="elevation-12">
                            <v-toolbar dark color="blue-grey darken-4">
                                <v-img height="30" position="left center" contain src="/img/logo_sun_n30.png" />
                                <v-spacer></v-spacer>
                                <v-icon color="grey" class="ml-n4">mdi-key</v-icon>
                            </v-toolbar>

                            <v-card-text>
                                <v-alert :value="error" type="error" color="error" icon="mdi-alert">
                                    {{errorMessage || 'Невідома помилка. Спробуйте ще раз пізніше'}}
                                </v-alert>
                                <div v-if="!isVerificationNeeded" >
                                    <validation-provider v-slot="{ errors, valid }" rules="required">
                                        <v-text-field @keyup.enter="passes(submitForm)" prepend-icon="person" name="login" label="Користувач" type="text" v-model="f.USER"></v-text-field>
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors, valid }" rules="required">
                                        <v-text-field @keyup.enter="passes(submitForm)" id="password" prepend-icon="lock" name="password" label="Пароль" type="password" v-model="f.PASS"></v-text-field>
                                    </validation-provider>
                                </div>
                                <!-- Требуется верификация -->
                                <div v-if="isVerificationNeeded && f.phone">
                                    <p class="error--text pl-8">Необхідне підтвердження входу.<br><br>На Ваш телефон {{hidePhone(f.phone)}} було надіслано SMS з кодом підтвердження.</p>
                                    <validation-provider v-if="codeCheck" v-slot="{ errors, valid }" rules="required|length:5">
                                        <v-text-field @keyup.enter="passes(submitForm)" v-show="codeCheck" :success="valid" persistent-hint hint="Введiть SMS-код підтвердження" prepend-icon="mdi-cellphone-text" name="code" label="Код з SMS" placeholder="00000" v-mask="maskCode" v-model="f.code"></v-text-field>
                                    </validation-provider>
                                </div>
                            </v-card-text>
                            <v-card-actions class="align-end px-4">
                                <v-card-text class="pa-0">
                                    <router-link to="/forgot">Забули пароль?</router-link>
                                </v-card-text>
                                <v-spacer></v-spacer>
                                <v-btn v-if="!emptyVerificationPhone" color="primary" :loading="loading" @click="passes(submitForm)" :disabled="invalid || !validated ">Увійти</v-btn>
                                <v-btn v-if="emptyVerificationPhone" text @click="resetData()">Повернутись</v-btn>
                            </v-card-actions>
                            <v-divider class="ma-4 mb-2"></v-divider>
                            <v-card-text class="">
                                Ви ще не є нашим клієнтом? Але бажаєте розпочати співробітництво - <a href="/new-client">заповніть анкету.</a>
                            </v-card-text>
                            <v-card-text class="caption text-center py-2 grey lighten-3"><a href="https://yugcontract.ua" target="_blank">yugcontract.ua</a></v-card-text>
                        </v-card>
                    </ValidationObserver>
                </v-flex>
            </v-layout>
        </v-container>
    </v-content>
</v-app>
</template>

<script>
import {
    mask
} from 'vue-the-mask'

import {
    mapActions
} from 'vuex'

import {
    ValidationObserver,
    ValidationProvider
} from "vee-validate"

export default {
    components: {
        ValidationProvider,
        ValidationObserver
    },
    directives: {
        mask
    },
    data: () => ({
        maskTel: '+38(###) ###-####',
        maskCode: '#####',
        drawer: null,
        errorS: [],
        error: false,
        errorMessage: '',
        loading: false,
        isVerificationNeeded: false,
        emptyVerificationPhone: false,
        codeCheck: false,
        f: {}
    }),
    props: {
        source: String
    },
    methods: {
        ...mapActions(['logIn', 'verifyByPhone', 'verify']),
        getLocalStorage(f) {
            let value = ''
            try {
                value = localStorage[f] ? JSON.parse(localStorage[f]) : ''
            } catch (error) {
                console.log(error)
                return ''
            }
            return value
        },
        setLocalStorage(f, v) {
            localStorage[f] = JSON.stringify(v)
        },
        hidePhone(phone) {
            return phone.substr(0, 3) + ' (XXX) XXX-XX-' + phone.substr(14, 2)
        },
        resetData() {
            this.error = false
            this.errorMessage = ''
            this.loading = false
            this.isVerificationNeeded = false
            this.emptyVerificationPhone = false
            this.f = {}
        },
        async submitForm(e) {
            this.errorMessage = ''
            this.error = false
            this.emptyVerificationPhone = false
            // Execute reCAPTCHA with action "login".
            await this.$recaptchaLoaded()
            this.f.reCAPTCHA = await this.$recaptcha('login')
            try {
                if (!this.f.deviceId) this.f.deviceId = this.getLocalStorage('deviceId') || ''
            } catch (error) {
                this.setLocalStorage('deviceId', '')
                console.log(error)
            }
            if (this.f.PASS && this.f.USER) {
                this.loading = true
                if (this.isVerificationNeeded) {
                    if (this.f.code) {
                        this.verify(this.f)
                            .then(data => {
                                if (data.token && data.refreshToken) {
                                    this.setLocalStorage('authToken', data.token)
                                    this.setLocalStorage('refreshToken', data.refreshToken)
                                    this.setLocalStorage('deviceId', data.deviceId || '')
                                }
                                //this.$router.push(this.$route.query.url ? this.$route.query.url : '/')
                                this.loading = true
                                window.location = this.$route.query.url ? this.$route.query.url : '/'
                            })
                            .catch(error => {
                                this.error = true
                                if (error.response && error.response.status >= 400) {
                                    this.errorMessage = 'Код введено невірно'
                                }
                            })
                            .finally(() => this.loading = false)

                    } else {
                        this.loading = false
                        this.errorMessage = `Відсутній код`
                    }
                } else {
                    this.logIn(this.f)
                        .then(async d => {
                            if (d.verificationNeeded) {
                                this.isVerificationNeeded = true
                                this.f.phone = d.phone
                                this.f.deviceId = d.deviceId
                                if (d.phone) {
                                    await this.$recaptchaLoaded()
                                    this.f.reCAPTCHA = await this.$recaptcha('login')

                                    this.verifyByPhone(this.f)
                                        .then(data => {
                                            this.codeCheck = true
                                            this.f.token = data.token

                                        })
                                        .catch(error => {
                                            this.error = true
                                            if (error.response && error.response.status == 417) {
                                                this.errorMessage = `Сталася помилка при формуванні SMS-коду. Спробуйте ще раз пізніше`
                                            } else if (error.response && error.response.status == 424){
                                                this.errorMessage = `Невірний Контактний телефон для надсилання SMS-коду`
                                            }
                                        })
                                        .finally(() => this.loading = false)
                                } else {
                                    this.error = true
                                    this.emptyVerificationPhone = true
                                    this.errorMessage = `Відсутній Контактний телефон для надсилання SMS`

                                }

                            } else {
                                if (d.token && d.refreshToken) {
                                    this.setLocalStorage('authToken', d.token)
                                    this.setLocalStorage('refreshToken', d.refreshToken)
                                    this.setLocalStorage('deviceId', d.deviceId || '')
                                }
                                this.loading = true
                                window.location = this.$route.query.url ? this.$route.query.url : '/'
                                //this.$router.push(this.$route.query.url ? this.$route.query.url : '/')
                            }
                        })
                        .catch(
                            (error) => {
                                this.error = true
                                if (error.response && error.response.status >= 400) {
                                    this.errorMessage = 'Помилка авторизації'
                                    if (error.response && error.response.status == 417) {
                                        this.errorMessage = `Сталася помилка. Спробуйте ще раз`
                                    } 
                                    if (error.response.status == 424) {
                                        this.emptyVerificationPhone = true
                                        this.errorMessage = 'Для підтвердження входу використовується SMS-підтвердження. На жаль, Ваш контактний телефон відсутній або невірний.'
                                    }
                                }
                            }
                        )
                        .finally(
                            () => {
                                this.loading = false
                            }
                        )
                }
            }
        }
    }
}
</script>
